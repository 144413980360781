<script setup lang="ts">
import { computed } from 'vue'
import RoomieButton from '@lahaus-roomie/roomie/src/components/RoomieButton/index.vue'
import LaHausIcon from '@lahaus-roomie/roomie/src/assets/icons/v2/la-haus.svg'
import RoomieIcon from '@lahaus-roomie/roomie/src/components/RoomieIcon/index.vue'
import CloseIcon from '@lahaus-roomie/roomie/src/assets/icons/close.svg'
import MenuLargeIcon from '@lahaus-roomie/roomie/src/assets/icons/menu-large.svg'
import UserIcon from '@lahaus-roomie/roomie/src/assets/icons/user.svg'
import { storeToRefs } from 'pinia'
import type { NavBarV2ComponentModule } from './types'
import RealEstateSearchNavBar from '@/components/BrandedV2/RealEstateSearch/NavBar/index.vue'
import { useUserStore } from '@/stores/userStore'
import './_index.scss'
import { useAppConfigStore } from '@/stores/useAppConfig'

const route = useRoute()
const userStore = useUserStore()

const appConfigStore = useAppConfigStore()
const { showRealEstateSearchNavBar } = storeToRefs(appConfigStore)

const { userLabel, isAuthenticated } = userStore

const props = withDefaults(defineProps<NavBarV2ComponentModule.Props>(), {
  isInSideMenu: false,
  isMobile: false
})

const emit = defineEmits(['on-side-menu-click'])

const sideMenuIcon = computed(() => {
  return props.isInSideMenu ? 'close' : 'menu-large'
})

const menuBtnExtraClass = computed(() => {
  return props.isInSideMenu ? '' : 'lg:hidden'
})

const handleSideMenuClick = () => {
  emit('on-side-menu-click')
}

const navigateToPage = async ({ path, query = {}, customConfig = {} }: { path: string; query?: Record<string, any>, customConfig?: Record<string, any>}): Promise<void> => {
  await navigateTo({
    path,
    query: {
      ...route.query,
      ...query
    }
  }, customConfig)
}
</script>

<script lang="ts">
export default {
  name: 'NavBarV2'
}
</script>

<template>
  <nav class="relative bg-white mb-lh-16 md:mb-0">
    <div class="relative flex flex-col md:flex-row lg:container items-start md:items-center justify-between global-navbar px-24 mx-auto">
      <div class="w-full md:w-fit flex flex-row justify-between items-center">
        <a
          href="/"
          aria-label="Logo La Haus">
          <RoomieIcon custom-styles="block w-106 h-24 text-pine-600">
            <LaHausIcon />
          </RoomieIcon>
        </a>

        <div
          class="global-navbar__menu w-60 h-60 cursor-pointer flex items-center justify-end"
          :class="menuBtnExtraClass"
          @click="handleSideMenuClick">
          <span class="w-32 h-32">
            <CloseIcon
              v-if="sideMenuIcon === 'close'"
              class="w-32 h-32" />

            <MenuLargeIcon
              v-else
              class="w-32 h-32" />
          </span>
        </div>
      </div>

      <RealEstateSearchNavBar
        v-if="showRealEstateSearchNavBar && !isMobile"
        class="md:!w-[65%]"
        @residential-complex-selected="navigateToPage" />

      <ul
        :class="{'!min-w-[20%]': showRealEstateSearchNavBar}"
        class="global-navbar__items-holder hidden flex-row justify-end items-center lg:flex">
        <template v-if="!showRealEstateSearchNavBar">
          <li
            v-for="(item, index) in items"
            :key="item.id +index"
            :class="item.componentConfig.class"
            class="global-navbar__item ml-20">
            <component
              :is="item.componentConfig.name === 'RoomieButton' ? RoomieButton : item.componentConfig.name"
              :id="item.id"
              :data-lh-id="`navbar-item-${item.id}`"
              v-bind="item.componentConfig.attrs"
              @click="item.componentConfig.attrs.click">
              <span>
                {{ item.label }}
              </span>
            </component>
          </li>
        </template>

        <li
          v-if="isAuthenticated"
          class="global-navbar__item ml-20 body-1-m text-pine-600">
          <span
            data-lh-id="navbar-item-user-label"
            class="flex justify-start"
            rel="nofollow">
            {{ userLabel }}

            <div class="flex items-center justify-center ml-4 -mt-4">
              <UserIcon class="global-navbar__icon w-24 h-24 fill-carbon-800 pointer-events-none" />
            </div>
          </span>
        </li>
      </ul>
    </div>
  </nav>
</template>
